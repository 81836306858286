import ListIcon from '@cms/components/BulletList/ListIcon'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'

import type { BulletListProps } from './BulletList.types'

const BulletList: React.FC<BulletListProps> = ({
  bulletColorAndTextOverride,
  listItems,
  isTextBold = false,
  qaIdPrefix = 'list',
  sx,
}) => {
  const theme = useTheme()

  const styles: Styles = {
    list: {
      p: '0px',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    listItem: {
      p: '0px',
      alignItems: 'start',
    },
    listIcon: {
      minWidth: '16px',
      color: bulletColorAndTextOverride || theme.palette.secondary.main,
      position: 'relative',
      mt: '0',
    },
    listText: {
      m: '0px',
      color: bulletColorAndTextOverride || theme.palette.text.secondary,
      '& .MuiListItemText-primary': {
        fontWeight: isTextBold ? 700 : 400,
        fontSize: '16px',
      },
      '&:dir(rtl)': {
        flexGrow: 0,
        mr: '8px',
        textAlign: 'start',
      },
    },
  }

  return (
    <Box display="flex" flexDirection="column" sx={sx} data-cy={`${qaIdPrefix}-bullet`}>
      <List dense sx={[styles?.list, styles?.highlightText]}>
        {listItems.map((listItem, index) => (
          <ListItem
            key={`${index}_${listItem}` as React.Key}
            sx={styles?.listItem}
            alignItems="flex-start"
            data-cy={`${qaIdPrefix}-bullet-${index}`}
          >
            <ListItemIcon sx={styles?.listIcon}>
              <ListIcon />
            </ListItemIcon>
            <ListItemText
              sx={styles?.listText}
              primary={listItem}
              primaryTypographyProps={{ variant: 'body2dense' }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default BulletList
