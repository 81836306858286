import type { ReactElement } from 'react'
import React from 'react'

import Box from '@mui/material/Box'

import BulletList from '../BulletList/BulletList'

interface Props {
  children: ReactElement[]
}

const RichTextList = ({ children }: Props) => {
  const listItems = children.map((parentNode) =>
    parentNode.props.children.map((childNode) => childNode.props.children),
  )

  return listItems.length > 0 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '32px', lg: '40px' },
        marginBottom: '16px',
      }}
      data-cy="rich-text-list"
    >
      <BulletList data-cy="rich-text-bullet-list" listItems={listItems} />
    </Box>
  ) : null
}

export default RichTextList
