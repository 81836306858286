import type { SVGProps } from 'react'

const SvgListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={22}
    viewBox="0 0 8 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 7H8V15H0V7Z" fill="currentColor" />
  </svg>
)
export default SvgListIcon
